import * as React from "react";
import Modal from "react-bootstrap/Modal";
import texts from "../../texts";

function ModalComponent(props) {
  console.log(props?.dataExperience?.description);

  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton style={{"border": 0}}>
        {/* <Modal.Title id="contained-modal-title-vcenter">
          Modal heading
        </Modal.Title> */}
      </Modal.Header>
      <Modal.Body>
        <div className="row d-flex justify-content-center align-items-center">
          <div className="col-8">
            <h3
              className="title mb-0 BW font-weight-6"
              dangerouslySetInnerHTML={{ __html: props?.dataExperience?.role }}
            />
            <h5 style={{ "font-size": ".8rem" }} className="mb-0 BW">
              {props?.dataExperience?.company}
            </h5>
            <div style={{ "font-size": ".7rem" }} className="exp-time">
              {props?.dataExperience?.time}
            </div>
          </div>
          <div className="col-4 text-right">
            <img
              className="image-fluid "
              src={props?.dataExperience?.image}
              alt="trydo"
              style={{ height: "4rem" }}
            />
          </div>
          <div className="col-12 mt-4">
            {props?.dataExperience?.description?.map((experience, idx) => (
              <>
                <h5 style={{ margin: "0" }} className={idx !== 0 ? "mt-4" : ""}>
                  {experience?.title}
                </h5>
                <ul className="list-group list-group-flush">
                  {experience?.data?.map((data) => (
                    <li
                      className="list-group-item"
                      style={{ padding: ".5rem 0rem .2rem 0" }}
                    >
                      <strong>{data?.bold}</strong> {data?.text}
                    </li>
                  ))}
                </ul>
              </>
            ))}
          </div>
        </div>
      </Modal.Body>
      {/* <Modal.Footer>
        <Button onClick={props.onHide}>Close</Button>
      </Modal.Footer> */}
    </Modal>
  );
}

export default ModalComponent;
