import React, { Component } from "react";
import { FiX, FiMenu } from "react-icons/fi";
import Scrollspy from "react-scrollspy";
import { SOCIAL_SHARE } from "../../../texts";

class HeaderThree extends Component {
  constructor(props) {
    super(props);
    this.menuTrigger = this.menuTrigger.bind(this);
    this.CLoseMenuTrigger = this.CLoseMenuTrigger.bind(this);
    this.stickyHeader = this.stickyHeader.bind(this);

    //  this.subMetuTrigger = this.subMetuTrigger.bind(this);
    window.addEventListener("load", function () {
      console.log("All assets are loaded");
    });
  }
  menuTrigger() {
    document.querySelector(".wrapper2").classList.toggle("menu-open");
    document.querySelector(".header-wrapper").classList.toggle("menu-open");
  }

  CLoseMenuTrigger() {
    document.querySelector(".wrapper2").classList.remove("menu-open");
    document.querySelector(".header-wrapper").classList.remove("menu-open");
  }

  stickyHeader() {}

  render() {
    const holaaa = () => {
      // var elmnt = document.getElementById("portfolio");
      // elmnt.scrollTop = elmnt.scrollTop + 500;
      // // element.scrollTop = element.scrollTop + 2000
      // // console.log(" HOlaa",window.scrollY)
      // // window.scrollY = window.scrollY + 10000
      // // console.log(" chano",window.scrollY)
      window.scroll({
        top: window.scrollY + -50,
      });
    };

    window.addEventListener("scroll", function () {
      var value = window.scrollY;
      if (value > 100) {
        document.querySelector(".header--fixed").classList.add("sticky");
        document.querySelector(".Stik").classList.add("sticky");
        // document.querySelector('.show-between').classList.remove('justify-content-between')
        // document.querySelector('.show-between').classList.add('justify-content-center')
      } else {
        document.querySelector(".header--fixed").classList.remove("sticky");
        document.querySelector(".Stik").classList.remove("sticky");
        // document.querySelector('.show-between').classList.remove('justify-content-center')
        // document.querySelector('.show-between').classList.add('justify-content-between')
      }
    });

    var elements = document.querySelectorAll(".has-droupdown > a");
    for (var i in elements) {
      if (elements.hasOwnProperty(i)) {
        elements[i].onclick = function () {
          this.parentElement
            .querySelector(".submenu")
            .classList.toggle("active");
          this.classList.toggle("open");
        };
      }
    }
    const { logo, color = "default-color" } = this.props;
    let logoUrl;
    if (logo === "light") {
      logoUrl = (
        <img src="/assets/images/logo/logo-light.png" alt="Digital Agency" />
      );
    } else if (logo === "dark") {
      logoUrl = (
        <img src="/assets/images/logo/logo-dark.png" alt="Digital Agency" />
      );
    } else if (logo === "symbol-dark") {
      logoUrl = (
        <img
          src="/assets/images/logo/logo-symbol-dark.png"
          alt="Digital Agency"
        />
      );
    } else if (logo === "symbol-light") {
      logoUrl = (
        <img
          src="/assets/images/logo/logo-symbol-light.png"
          alt="Digital Agency"
        />
      );
    } else {
      logoUrl = <img src="/assets/images/logo/logo.png" alt="Digital Agency" />;
    }

    return (
      <header>
        <div
          className={`header-area header-style-two header--fixed ${color} d-none d-md-block justify-content-center`}
        >
          <div className="header-wrapper">
            <div className="header-left d-flex align-items-center">
              <nav className="mainmenunav d-lg-block ml-5">
                <Scrollspy
                  className="mainmenu"
                  items={["home", "about", "work-experience", "portfolio", "contact"]}
                  currentClassName="is-current"
                  offset={-200}
                >
                  <li>
                    <a href="#home" onClick={this.CLoseMenuTrigger}>
                      Home
                    </a>
                  </li>
                  <li>
                    <a href="#about" onClick={this.CLoseMenuTrigger}>
                      About Me
                    </a>
                  </li>
                  {/* <li><a href="#service" onClick={this.CLoseMenuTrigger}>Service</a></li> */}
                  <li>
                    <a href="#work-experience" onClick={this.CLoseMenuTrigger}>
                      Work Experience
                    </a>
                  </li>
                  <li>
                    <a href="#portfolio" onClick={this.CLoseMenuTrigger}>
                      Portfolio
                    </a>
                  </li>
                  {/* <li><a href="#blog" onClick={this.CLoseMenuTrigger}>Blog</a></li> */}
                  <li>
                    <a href="#contact" onClick={this.CLoseMenuTrigger}>
                      Contact
                    </a>
                  </li>
                </Scrollspy>
              </nav>
            </div>
            <div className="header-right">
              <div className="social-share-inner mr-5">
                <ul className="social-share social-style--2 color-black d-flex justify-content-start liststyle">
                  {SOCIAL_SHARE.map((val, i) => (
                    <li key={i}>
                      <a
                        href={`${val.link}`}
                        className="text-right"
                        target={`_blank`}
                      >
                        {<val.Social />}
                      </a>
                    </li>
                  ))}
                </ul>
              </div>
              {/* Start Humberger Menu  */}
              <div className="humberger-menu d-block d-lg-none pl--20">
                <span
                  onClick={this.menuTrigger}
                  className="menutrigger text-white"
                >
                  <FiMenu />
                </span>
              </div>
              {/* End Humberger Menu  */}
              <div className="close-menu d-block d-lg-none">
                <span onClick={this.CLoseMenuTrigger} className="closeTrigger">
                  <FiX />
                </span>
              </div>
            </div>
          </div>
        </div>

        <div
          className={`header-area header-style-two header--fixed Stik ${color} d-sm-block d-md-none justify-content-center`}
        >
          <div className="header-wrapper wrapper2 w-100">
            <div className="header-left d-flex align-items-center">
              <nav className="mainmenunav d-lg-block ml--50">
                <Scrollspy
                  className="mainmenu"
                  items={["home", "about", "work-experience", "portfolio", "contact"]}
                  currentClassName="is-current"
                  offset={-200}
                >
                  <li>
                    <a href="#home" onClick={this.CLoseMenuTrigger}>
                      Home
                    </a>
                  </li>
                  <li>
                    <a href="#about" onClick={this.CLoseMenuTrigger}>
                      About Me
                    </a>
                  </li>
                  <li>
                    <a href="#work-experience" onClick={this.CLoseMenuTrigger}>
                      Work Experience
                    </a>
                  </li>
                  {/* <li><a href="#service" onClick={this.CLoseMenuTrigger}>Service</a></li> */}
                  <li>
                    <a href="#portfolio" onClick={this.CLoseMenuTrigger}>
                      Portfolio
                    </a>
                  </li>
                  {/* <li><a href="#blog" onClick={this.CLoseMenuTrigger}>Blog</a></li> */}
                  <li>
                    <a href="#contact" onClick={this.CLoseMenuTrigger}>
                      Contact
                    </a>
                  </li>
                </Scrollspy>
              </nav>
            </div>
            <div className="header-right d-flex w-100 justify-content-between">
              <div className="social-share-inner">
                <ul className="social-share social-style--2 color-black d-flex justify-content-start liststyle">
                  {SOCIAL_SHARE.map((val, i) => (
                    <li key={i}>
                      <a
                        href={val.link}
                        className="text-center"
                        target={`_blank`}
                      >
                        {<val.Social />}
                      </a>
                    </li>
                  ))}
                </ul>
              </div>
              {/* Start Humberger Menu  */}
              <div className="humberger-menu d-block d-lg-none pl--20">
                {/* style={{backgroundColor: '#090909'}} */}
                <span
                  onClick={this.menuTrigger}
                  className="menutrigger"
                  style={{ color: "#ffffff" }}
                >
                  <FiMenu />
                </span>
              </div>
              {/* End Humberger Menu  */}
              <div className="close-menu d-block d-lg-none">
                <span onClick={this.CLoseMenuTrigger} className="closeTrigger">
                  <FiX />
                </span>
              </div>
            </div>
          </div>
        </div>
      </header>
    );
  }
}
export default HeaderThree;
