import axios from 'axios'
import React, { useState } from 'react'

const ChangeUrl = () => {
  const [url, setUrl] = useState('')
  const [password, setPassword] = useState('')
  const [message, setMessage] = useState('')

  const updateUrl = async () => {
    try {
      //'http://localhost:5000/samuelbuendia.com/changeUrl'
      const data = await axios.post(
        'https://send-email-node-ws5a-dev.fl0.io/samuelbuendia.com/changeUrl',
        {
          url,
          password
        }
      )
      setMessage(data?.data?.message ?? 'Error')
      console.log(data)
    } catch (error) {
      console.log(error)
    }
  }

  const styles = {
    container: {
      display: 'flex',
      height: '70vh',
      justifyContent: 'center',
      alignItems: 'center',
      margin: '0rem 2rem',
    },
    content: {}
  }

  return (
    <div style={styles.container}>
      <div style={styles.content}>
        <input
          type="text"
          onChange={(e) => {
            setUrl(e.target.value)
          }}
        />
        <input
          type="password"
          onChange={(e) => {
            setPassword(e.target.value)
          }}
        />
        <button onClick={updateUrl}>Change</button>
        <div>{message}</div>
      </div>
    </div>
  )
}

export default ChangeUrl
