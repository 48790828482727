import { FaLinkedinIn, FaGithub } from "react-icons/fa";
var texts = {
  welcome: {
    title: "Hi, I’m Samuel Buendía",
    TextLoop: ["Frontend Dev.", "Backend Dev.", "Full Stack Dev."],
    location: "based in Australia"
  },
  about: {
    title: "About Me",
    description: "\n      <p>Experienced software developer with a strong background in coding, design, and maintenance of websites and information systems. Proficient in a variety of programming languages and web technologies, I have developed expertise through <strong> more than 5 years of experience </strong> and a solid educational foundation. I am dynamic, proactive, and organized, with skills in leadership, decision-making, and teamwork.</p>\n    <p>I excel in thriving within dynamic environments and collaborating effectively with multidisciplinary teams. My proactive approach, coupled with strong communication skills and a commitment to continuous learning, enables me to make valuable contributions to your team\u2019s success.</p>\n    ",
    mainSkills: ["React.Js", "Node.Js", "Next.Js", "JavaScript", "TypeScript", "HTML / CSS", "Boostrap", "Tailwind", "Angular", "React Native", "Git", "SQL", "PostgreSQL", "Firebase", "MongoDB", "Docker", "Django", "GraphQL", "Design", "Vue.js"],
    experience: [{
      position: "Fullstack Dev",
      company: "Keo World",
      time: "June 2022 - Present"
    }, {
      position: "DevOps",
      company: "Itac",
      time: "October 2021 - May 2022"
    }, {
      position: "Fullstack Dev",
      company: "Ingenious Key",
      time: "February 2021 - October 2021"
    }, {
      position: "Frontend Junior Dev ",
      company: "Klaus Solutions",
      time: "October 2020 - February 2021"
    }, {
      position: "Junior Dev ",
      company: "Redes Humanas S.A",
      time: "October 2018 - October 2020"
    }],
    educations: [{
      name: "Master of Information Technology",
      place: "AIAT - Australian Institute of Advanced Technologies",
      time: "September 2024 - Present"
    }, {
      name: "Software Development Technologist",
      place: "SENA",
      time: "April 2020 - April 2022"
    }, {
      name: "Full Stack Development Career",
      place: "Coder House",
      time: "May 2020 April 2022"
    }, {
      name: "MisionTic Gov Code Program",
      place: "Universidad Nacional De Colombia",
      time: "April 2021 - December 2021"
    }, {
      name: "Front-end Development Career",
      place: "Coder House",
      time: "January 2020 - June 2021"
    }, {
      name: "Software Programming Technician ",
      place: "SENA",
      time: "April 2018 - April 2019"
    }],
    courses: [{
      name: "Desarrollo de Aplicaciones",
      littleText: "React Native",
      institute: "Coder House",
      date: "January 2023"
    }, {
      name: "MongoDB",
      institute: "MongoDB University",
      date: "November 2022"
    }, {
      name: "Google GCP Foundations",
      institute: "Google",
      date: "October 2022"
    }, {
      name: "Backend Node.Js",
      institute: "Coder House",
      date: "April 2022"
    }, {
      name: "Docker",
      institute: "Coder House",
      date: "November 2021"
    }, {
      name: "React",
      institute: "Coder House",
      date: "May 2021"
    }, {
      name: "Javascript",
      institute: "Coder House",
      date: "March 2021"
    }, {
      name: "Web Develop",
      institute: "Coder House",
      date: "December 2020"
    }, {
      name: "Programming",
      institute: "Platzi",
      date: "December 2019"
    }, {
      name: "Git Y GitHub",
      institute: "Platzi",
      date: "October 2019"
    }, {
      name: "Software Ing",
      institute: "Platzi",
      date: "January 2019"
    }]
  },
  experience: {
    title: "My Work Experience",
    description: "\n      Below, you'll find an overview of the companies where I\n      have contributed my skills and expertise. My experience\n      spans various roles, each providing unique opportunities\n      that have allowed me to enhance my abilities and\n      contribute to team success.",
    jobs: [{
      company: "Keo World",
      image: "/assets/images/experience/companies/keo.jpeg",
      role: "Fullstack Developer",
      time: "June 2022 - Present",
      shortDescription: "Fullstack Development with React.js, Nodejs... ",
      description: [{
        title: "Responsibilities / Stack",
        data: [{
          bold: "Frontend",
          text: "development with React, HTML, CSS, Bootstrap."
        }, {
          bold: "Backend",
          text: "development with Node.js, Typescript, Express, Fastify, Typeorm, Sequalize, REST API, GraphQL, Microservices, Publish/Subscribe and Docker."
        }, {
          bold: "Database Management",
          text: "with PostgreSQL, Firebase and Mongodb."
        }]
      }, {
        title: "Proyects",
        data: [{
          bold: "B2B Platform: ",
          text: "System for managing, customers, loans, rates and interest."
        }, {
          bold: "KeoRails: ",
          text: "System to manage loans using digital currencies and blockchain (Includes integration with third parties)."
        }, {
          bold: "HubSpot Adapter: ",
          text: "Microservice for sharing information between HubSpot and internal systems."
        }, {
          bold: "CRM: ",
          text: "System for centralized information visualization and report management."
        }]
      }]
    }, {
      company: "ITAC",
      image: "/assets/images/experience/companies/itac.jpeg",
      role: "DevOps",
      time: "October 2021 - May 2022",
      shortDescription: "Process automation, setting up environments with Docker...",
      description: [{
        title: "Responsibilities / Stack",
        data: [{
          text: "Setting up environments with Docker."
        }, {
          text: "Automation of company processes."
        }, {
          text: "Assisted with the administration and configuration of Linux servers."
        }]
      }, {
        title: "Proyects",
        data: [{
          bold: "Davivienda Portal (Bank):",
          text: "Provided support for DevOps configurations and automation."
        }]
      }]
    }, {
      company: "Ingenious Key",
      image: "/assets/images/experience/companies/ingeniouskey.jpeg",
      role: "Fullstack Developer",
      time: "February 2021 - October 2021",
      shortDescription: "Fullstack Development with Angular, Python Django...",
      description: [{
        title: "Responsibilities / Stack",
        data: [{
          bold: "Frontend",
          text: "development with Angular, React, CSS, HTML, Boostrap, JavaScript"
        }, {
          bold: "Backend",
          text: "development with python Django."
        }, {
          bold: "Database Management",
          text: "with PostgreSQL."
        }]
      }, {
        title: "Proyects",
        data: [{
          bold: "Brinks Software: ",
          text: "Cash Management Software, tracking the movement of cash vehicles, managing cash inventory levels, monitoring routes and schedules."
        }]
      }]
    }, {
      company: "Klaus Solutions",
      image: "/assets/images/experience/companies/klaus.jpeg",
      role: "Frontend Developer",
      time: "October 2020 - February 2021",
      shortDescription: "Created Websites using HTML, CSS, JavaScript...",
      description: [{
        title: "Responsibilities / Stack",
        data: [{
          bold: "Frontend",
          text: "development with Vue, React, HTML, CSS, Bootstrap."
        }, {
          bold: "Designed and developed",
          text: "website layouts using modern web technologies."
        }, {
          bold: "Database Management",
          text: "with Mongodb."
        }]
      }, {
        title: "Proyects",
        data: [{
          bold: "Klaus Dashboard:",
          text: "System for the administration of customer e-commerce, inventory, text editing, and layout components."
        }, {
          bold: "Loromoro:",
          text: "Implementation and layout development of the e-commerce website."
        }, {
          bold: "Verdi: ",
          text: "Implementation and layout development of the e-commerce website."
        }]
      }]
    }, {
      company: "Redes Humanas S.A",
      image: "/assets/images/experience/companies/redeshumanas.jpeg",
      role: "Fullstack Developer",
      time: "October 2018 - October 2020",
      shortDescription: "Fullstack Development with Angular, PHP Laravel...",
      description: [{
        title: "Responsibilities / Stack",
        data: [{
          bold: "Frontend",
          text: "development with Angular, React, Javascript, HTML, CSS, Bootstrap."
        }, {
          bold: "Backend",
          text: "development with Node.js and Laravel PHP."
        }, {
          bold: "Database Management",
          text: "with MySQL."
        }]
      }, {
        title: "Proyects",
        data: [{
          bold: "Intranet Kaizen:",
          text: "System to manage internal company processes, clients, stages, recruitment, time tracking, and reports across different cities."
        }]
      }]
    }]
  },
  jobs: {
    title: "Some of my Jobs",
    description: "Here are some of the pages and systems I have developed or contributed to. This selection highlights my independent work, distinct from the corporate systems I have developed or been involved with."
  },
  contact: {
    title: "Contact Me.",
    description: "If you'd like more information or are interested in discussing potential job opportunities or freelance work, feel free to reach out. I'm open to new projects and collaborations. You can contact me via phone at ",
    phone: {
      plain: "+610434801481",
      print: "+61 0434 801 481"
    },
    email: "sbuendiapuyo@gmail.com",
    notificationURL: "https://site--emails--ghbf84gsynl4.code.run/samuelbuendia.com/"
  }
};
export var SOCIAL_SHARE = [{
  Social: FaLinkedinIn,
  link: "https://www.linkedin.com/in/samuelbuendia/"
}, {
  Social: FaGithub,
  link: "https://github.com/samuelb13p"
}];
export default texts;