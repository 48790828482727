import React, { Component } from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import texts from "../../../texts";
import { name } from "file-loader";

class TabsTwo extends Component {
  render() {
    let tab1 = "Main Skills",
      tab2 = "Education",
      tab3 = "Courses";
    // tab4 = "Experience",
    const { tabStyle } = this.props;
    return (
      <div>
        {/* Start Tabs Area */}
        <div className="tabs-area">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <Tabs>
                  <TabList className={`${tabStyle}`}>
                    <Tab>{tab1}</Tab>
                    <Tab>{tab2}</Tab>
                    <Tab>{tab3}</Tab>
                    {/* <Tab>{tab4}</Tab> */}
                  </TabList>

                  <TabPanel>
                    <div className="container mt-2 text-regular">
                      <div className="row mt-4">
                        {texts.about.mainSkills.map((Skill, idx) => (
                          <div className="col-6 col-md-4 pl-0 mt-2" id={idx}>
                            <div className="title">  <strong>{Skill}</strong> </div>
                          </div>
                        ))}
                      </div>
                    </div>
                  </TabPanel>

                  <TabPanel>
                    <div className="container mt-2 text-regular">
                      <div className="row">
                        {texts.about.educations.map((education, idx) => (
                          <div
                            className="col-lg-6 col-md-6 pl-0 pr-5 mt-3 flex-center"
                            id={idx}
                          >
                            <div className="mt-2 pr-5 border-div">
                              <div className="title">
                                <strong>{education.name}</strong>
                              </div>
                              <div className="time">{education.time}</div>
                              <div className="place">
                                {education.place}
                              </div>
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                  </TabPanel>

                  <TabPanel>
                    <div className="container mt-2 text-regular">
                      <div className="row">
                        {texts.about.courses.map((course, idx) => (
                          <div className="col-12 col-md-4 pl-0" id={idx}>
                            <div className="mt-4 pr-5">
                              <div className="title pr-5 pr-md-0">
                                <strong>{course.name}</strong>
                              </div>
                              {course?.littleText && (
                                <div style={{ "font-size": ".6rem" }}>
                                  {course.littleText}
                                </div>
                              )}
                              <div className="time">{course.date}</div>
                              <div className="place font-italic pr-5">
                                {course.institute}
                              </div>
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                  </TabPanel>

                  {/* <TabPanel>
                    <div className="single-tab-content">
                      <ul>
                        {texts.about.experience.map((job, idx) => (
                          <li className={idx === 0 ? "mt-2" : ""}>
                            <strong> {job.position}</strong>
                            <span> - {job.company}</span> <br /> {job.time}
                          </li>
                        ))}
                      </ul>
                    </div>
                  </TabPanel> */}
                </Tabs>
              </div>
            </div>
          </div>
        </div>
        {/* End Tabs Area */}
      </div>
    );
  }
}

export default TabsTwo;
