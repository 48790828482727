import React, { Component } from "react";
import axios from "axios";
import texts from "../../../texts";

class ContactThree extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: "",
      email: "",
      subject: "",
      message: "",
      response_msg: undefined,
      sending_email: false,
    };
  }

  enviarEmail = async (e) => {
    e.preventDefault();
    this.setState({ sending_email: true });
    this.setState({ response_msg: "" });
    const { name, email, subject, message } = this.state;
    try {
      const form = await axios.post(
        texts.contact.notificationURL,
        {
          name,
          email,
          subject,
          message,
        }
      );
      this.setState({ sending_email: false });
      this.setState({ response_msg: form.data.message });
      this.setState({
        name: "",
        email: "",
        subject: "",
        message: "",
      });
    } catch (error) {
      this.setState({ sending_email: false });
      this.setState({ response_msg: "The message was not sent " + error });
    }
  };

  render() {
    return (
      <div className="contact-form--1">
        <div className="container">
          <div className="row row--35 align-items-center">
            <div className="col-lg-6 order-2 order-lg-1">
              <div className="section-title text-left mb--50 mt-3 mt-md-0">
                <h2 className="title">{this.props.contactTitle}</h2>
                <p className="description text-justify">
                  {texts.contact.description}
                  <a href={`tel:${texts.contact.phone.plain}`}>
                    {texts.contact.phone.print}
                  </a>{" "}
                  or email at
                  <a href={`mailto:${texts.contact.email}`}>
                    {" "}
                    {texts.contact.email}
                  </a>{" "}
                </p>
              </div>
              <div className="form-wrapper">
                <form onSubmit={this.enviarEmail} method="post">
                  <label htmlFor="item01">
                    <input
                      type="text"
                      name="name"
                      id="item01"
                      required
                      value={this.state.name}
                      onChange={(e) => {
                        this.setState({ name: e.target.value });
                      }}
                      placeholder="Your Name"
                    />
                  </label>

                  <label htmlFor="item02">
                    <input
                      type="email"
                      name="email"
                      id="item02"
                      required
                      value={this.state.email}
                      onChange={(e) => {
                        this.setState({ email: e.target.value });
                      }}
                      placeholder="Your email"
                    />
                  </label>

                  <label htmlFor="item03">
                    <input
                      type="text"
                      name="subject"
                      id="item03"
                      required
                      value={this.state.subject}
                      onChange={(e) => {
                        this.setState({ subject: e.target.value });
                      }}
                      placeholder="Write a Subject"
                    />
                  </label>
                  <label htmlFor="item04">
                    <textarea
                      type="text"
                      id="item04"
                      name="message"
                      required
                      value={this.state.message}
                      onChange={(e) => {
                        this.setState({ message: e.target.value });
                      }}
                      placeholder="Your Message"
                    />
                  </label>
                  <button
                    className="rn-button-style--2 btn-solid"
                    type="submit"
                    value="submit"
                    name="submit"
                    id="mc-embedded-subscribe"
                  >
                    Submit
                  </button>
                  <div className="d-flex justify-content-between mt-3">
                    {this.state.sending_email ? <h3>Sending...</h3> : ""}
                    {this.state.response_msg ? (
                      <h3>{this.state.response_msg}</h3>
                    ) : (
                      ""
                    )}
                  </div>
                </form>
              </div>
            </div>
            <div className="col-lg-6 order-1 order-lg-2">
              <div className="mb_md--30 mb_sm--30 p-md-5">
                <img
                  src={`${this.props.contactImages}`}
                  alt="trydo"
                  style={{ borderRadius: "30px" }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default ContactThree;
