import React, { useEffect } from 'react'
import axios from 'axios'

const Info = () => {
  useEffect(() => {
    const fetchUrl = async () => {
      try {
        //'http://localhost:5000/samuelbuendia.com/findUrl'
        const data = await axios.get(
          'https://send-email-node-ws5a-dev.fl0.io/samuelbuendia.com/findUrl',
          {}
        )
        console.log(data.data)
        window.location.replace(data.data.url)
      } catch (error) {
        console.log(error)
      }
    }
    fetchUrl()
  }, [])

  return (
    <div
      style={{
        height: '70vh',
        display: 'flex',
        margin: '0rem 1rem 0 1rem',
        'justify-content': 'center',
        'align-items': 'center'
      }}
    >
      <h1>Rediriginedo Página...</h1>
    </div>
  )
}

export default Info
