import React from "react";
import texts from "../../../texts";

export default function ServiceThree({
  column,
  item,
  setShowModal,
  setDataExperience,
}) {
  const ServiceContent = texts.experience.jobs.slice(0, item);

  const modalHandle = (experience) => {
    setDataExperience(experience);
    setShowModal(true);
  };

  return (
    <React.Fragment>
      <div className="row">
        {ServiceContent.map((val, i) => (
          <div
            className={`${column} cursor-pointer margin-top-40`}
            key={i}
            onClick={() => modalHandle(val)}
          >
            <div className="service service__style--2 h-100 mt-0 d-flex color-BW">
              <div className="row d-flex justify-content-center align-items-center">
                <div className="col-4">
                  <img
                    className="image-fluid "
                    src={val.image}
                    alt="trydo"
                    style={{ height: "4rem" }}
                  />
                </div>
                <div className="col-8">
                  <h3
                    className="title mb-0 BW font-weight-6"
                    dangerouslySetInnerHTML={{ __html: val.role }}
                  />
                  <h5 style={{ "font-size": ".8rem" }} className="mb-0 BW">
                    {val.company}
                  </h5>
                </div>
                <div className="col-12 mt-3">
                  <div className="exp-time">{val.time}</div>
                </div>
                <div className="col-12">
                  <p
                    className="description"
                    dangerouslySetInnerHTML={{ __html: val.shortDescription }}
                  />
                  <a
                    className="btn-transparent rn-btn-dark BW"
                    onClick={() => modalHandle(val)}
                  >
                    Read More
                  </a>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </React.Fragment>
  );
}
