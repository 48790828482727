import React, { Component } from "react";

const PortfolioListContent = [
    {
        image: 'url(/assets/images/portfolio/portfolio-1.jpg)',
        category: 'Development And Design',
        title: 'Ganemos Más',
        bgcolor: "dark",
        description: '',
        url: "https://ganemosmas.com/"
    },
    {
        image: 'url(/assets/images/portfolio/portfolio-2.jpg)',
        category: 'Development And Design',
        title: 'La Escuela Abierta',
        bgcolor: "light",
        description: '',
        url: "https://laescuelabierta.com/"
    },
    {
        image: 'url(/assets/images/portfolio/portfolio-3.jpg)',
        category: 'Development And Design',
        title: 'Carrito Compras',
        bgcolor: "dark",
        description: '',
        url: "https://ecommerce.samuelbuendia.com/"
    },
    {
        image: 'url(/assets/images/portfolio/portfolio-4.jpg)',
        category: 'Development And Design',
        title: 'Rooms',
        bgcolor: "light",
        description: '',
        url: "https://rooms.samuelbuendia.com/"
    },
    {
        image: 'url(/assets/images/portfolio/portfolio-5.jpg)',
        category: 'Layout Designer',
        title: 'Verdi',
        bgcolor: "dark",
        description: '',
        url: "https://verdi.com.co/es/"
    },
    {
        image: 'url(/assets/images/portfolio/portfolio-6.jpg)',
        category: 'Layout Designer',
        title: 'Loromoro',
        bgcolor: "light",
        description: '',
        url: "https://loromoro.com/"
    }
]

class PortfolioList extends Component{
    render(){

        const colorText = (color) => {
            return color === 'light' ? 
            { color: '#000000',
            borderColor: '#000000'}
            : {color: '#FFFFFF',
            borderColor: '#FFFFFF'}
        }

        const {column , styevariation } = this.props;
        const list = PortfolioListContent.slice(0 , this.props.item);
        return(
            <React.Fragment> 
                {list.map((value , index) => (
                    <div className={`${column}`} key={index}>
                        <div className={`portfolio ${styevariation}`}>
                            <div className="thumbnail-inner">
                                <div className={`thumbnail`} style={{backgroundImage: value.image}}></div>
                                {/* <div className={`bg-blr-image ${value.image}`}></div> */}
                            </div>
                            <div className="content">
                                <div className="inner">
                                    <p style={colorText(value.bgcolor)}>{value.category}</p>
                                    <h4><a style={colorText(value.bgcolor)} href={value.url}>{value.title}</a></h4>
                                    <span className="text-white">{value.description}</span>
                                    <div className="portfolio-button">
                                        <a style={colorText(value.bgcolor)} className={`rn-btn`} href={value.url} target={"_blank"}>Visit Website</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                ))}
               
            </React.Fragment>
        )
    }
}
export default PortfolioList;