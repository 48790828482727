import React, { Component } from "react";
import ReactDOM from "react-dom";
import "./index.scss";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import * as serviceWorker from "./serviceWorker";
import DarkPortfolioLanding from "./dark/PortfolioLanding";
import error404 from "./elements/error404";
import Info from "./home/Info";
import ChangeUrl from "./home/ChangeUrl";

class Root extends Component {
  //  ( function loop() {
  //     setTimeout(async () => {
  //       console.log("TODOOO BIEN");
  //       await fetch("https://send-email-node-dev-abpn.3.us-1.fl0.io/");
  //     }, 100);
  //   })

  render() {
    return (
      <BrowserRouter basename={`${process.env.PUBLIC_URL}/`}>
        <Switch>
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/`}
            component={DarkPortfolioLanding}
          />
          <Route path={`${process.env.PUBLIC_URL}/qr`} component={Info} />
          <Route
            path={`${process.env.PUBLIC_URL}/changeUrl`}
            component={ChangeUrl}
          />
          <Route path={`${process.env.PUBLIC_URL}/404`} component={error404} />
          <Route component={error404} />
        </Switch>
      </BrowserRouter>
    );
  }
}

ReactDOM.render(<Root />, document.getElementById("root"));
serviceWorker.register();

const activateServerEmails = async () =>
  setInterval(async () => {
    await fetch("https://send-email-node-dev-abpn.3.us-1.fl0.io/");
  }, 43200000);

activateServerEmails();
