import React, { useEffect, useState } from "react";
import ScrollToTop from "react-scroll-up";
import { FiChevronUp } from "react-icons/fi";
import Helmet from "../component/common/Helmet";
import TextLoop from "react-text-loop";
import HeaderThree from "../component/header/HeaderThree";
import FooterTwo from "../component/footer/FooterTwo";
import TabTwo from "../elements/tab/TabTwo";
import ContactThree from "../elements/contact/ContactThree";
import PortfolioList from "../elements/portfolio/PortfolioList";
import ServiceList from "../elements/service/ServiceList";
import BlogContent from "../elements/blog/BlogContent";
import texts from "../../texts.js";
import { Parallax } from "react-parallax";
import ModalComponent from "../component/ModalComponent.jsx";

const SlideList = [
  {
    textPosition: "text-left py-5 py-md-4",
    category: "Welcome",
    description: "",
    buttonText: "",
    buttonLink: "",
  },
];

const image1 = "/assets/images/bg/paralax/soporte.jpeg";

const PortfolioLanding = () => {
  const [showModal, setShowModal] = useState(false);
  const [dataExperience, setDataExperience] = useState(false);

  return (
    <div className="active-dark">
      <Helmet pageTitle="Samuel Buendía" />

      <HeaderThree homeLink="/" logo="symbol-dark" color="color-black" />
      {/* Start Slider Area   */}
      <div id="home" className="fix" style={{ height: "100vh" }}>
        <div className="slider-wrapper h-100">
          {/* Start Single Slide */}
          {SlideList.map((value, index) => (
            <div
              className="slide personal-portfolio-slider slider-paralax slider-style-3 d-flex align-items-center justify-content-center bg_image bg_image--25 h-100"
              key={index}
            >
              <div className="container">
                <div className="row">
                  <div className="col-lg-12">
                    <div className={`inner ${value.textPosition}`}>
                      {value.category ? <span>{value.category}</span> : ""}
                      <h1 className="title">
                        {" "}
                        {texts?.welcome.title} <br />
                      </h1>
                      <h1 className="title">
                        <TextLoop>
                          {texts.welcome.TextLoop.map((e) => (
                            <span> {e} </span>
                          ))}
                        </TextLoop>{" "}
                      </h1>
                      <h2>{texts?.welcome.location}.</h2>
                      {value.description ? (
                        <p className="description">{value.description}</p>
                      ) : (
                        ""
                      )}
                      {value.buttonText ? (
                        <div className="slide-btn">
                          <a
                            className="rn-button-style--2 btn-primary-color"
                            href={`${value.buttonLink}`}
                          >
                            {value.buttonText}
                          </a>
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}
          {/* End Single Slide */}
        </div>
      </div>
      {/* End Slider Area   */}

      {/* Start About Area */}
      <div id="about" className="fix pb-5 pb-md-0 pt-5 pt-md-0 bg_color--1">
        <div className="about-area ptb--120">
          <div className="about-wrapper">
            <div className="container">
              <div className="row row--35 align-items-center">
                <div className="col-lg-5">
                  <div className="">
                    <img
                      className="w-100"
                      src="/assets/images/about/about-8.jpg"
                      alt="About Images"
                      style={{ borderRadius: "30px" }}
                    />
                  </div>
                </div>
                <div className="col-lg-7">
                  <div className="about-inner inner">
                    <div className="section-title ">
                      <h2 className="title">{texts.about.title}</h2>
                      <p
                        className="description text-justify"
                        dangerouslySetInnerHTML={{
                          __html: texts.about.description,
                        }}
                      />
                    </div>
                    <div className="row mt--30">
                      <TabTwo tabStyle="tab-style--1" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* End About Area */}

      {/* Start Service Area  */}
      <div id="work-experience" className="">
        <Parallax
          className="rn-paralax-service fix pb-5 pb-md-0 pt-5 pt-md-0"
          bgImage={image1}
          strength={1000}
        >
          <div className="service-area ptb--120">
            <div className="container">
              <div className="row align-items-center">
                <div className="col-lg-12">
                  <div className="section-title service-style--2 text-center mb--30 mb_sm--0">
                    <h2>{texts.experience.title}</h2>
                    <p class="text-justify mt-3 experience-desc">
                      {texts.experience.description}
                    </p>
                  </div>
                </div>
              </div>
              <ServiceList
                item="6"
                column="col-lg-4 col-md-6 col-sm-6 col-12"
                setShowModal={setShowModal}
                setDataExperience={setDataExperience}
              />
            </div>
          </div>
        </Parallax>
      </div>

      {/* End Service Area  */}

      <ModalComponent
        show={showModal}
        onHide={() => setShowModal(false)}
        dataExperience={dataExperience}
      />

      {/* Start Service Area  */}
      {/* <div id="service" className="fix pt-5 pt-md-0">
                <div className="service-area creative-service-wrapper ptb--120 bg_color--5" >
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="section-title text-center service-style--3 mb--30 mb_sm--0">
                                    <h2 className="title">My Awesome Service</h2>
                                    <p>There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration.</p>
                                </div>
                            </div>
                        </div>
                        <div className="row creative-service">
                            <div className="col-lg-12">
                                <ServiceList item="6" column="col-lg-4 col-md-6 col-sm-6 col-12 text-left" />
                            </div>
                        </div>
                    </div>
                </div>  
            </div> */}
      {/* End Service Area  */}

      {/* Start Portfolio Area */}
      <div id="portfolio" className="fix pb-5 pb-md-0 pt-5 pt-md-0 bg_color--5">
        <div className="portfolio-area ptb--120">
          <div className="portfolio-sacousel-inner">
            <div className="container">
              <div className="row">
                <div className="col-lg-12">
                  <div className="section-title text-center service-style--3 mb--30 mb_sm--0">
                    <h2 className="title">{texts.jobs.title}</h2>
                    <p className="font-weight-5 text-justify mt-3">
                      {" "}
                      {texts.jobs.description}
                    </p>
                  </div>
                </div>
              </div>
              <div className="row">
                <PortfolioList
                  styevariation="text-center mt--40"
                  column="col-lg-4 col-md-6 col-sm-6 col-12"
                  item="6"
                />
              </div>
              {/* <div className="row">
                    <div className="col-lg-12">
                        <div className="view-more-btn mt--60 mt_sm--30 text-center">
                            <a className="rn-button-style--2 btn-solid" href="/blog"><span>View More</span></a>
                        </div>
                    </div>
                </div> */}
            </div>
          </div>
        </div>
      </div>
      {/* End Portfolio Area */}

      {/* Start Blog Area */}
      {/* <div id="blog" className="fix pt-5 pt-md-0">
                <div className="rn-blog-area ptb--120 bg_color--5 mb-dec--30">
                    <div className="container">
                        <div className="row align-items-end">
                            <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                                <div className="section-title text-center">
                                    <h2>Latest News</h2>
                                    <p>There are many variations of passages of Lorem Ipsum available, <br />but the majority have suffered alteration.</p>
                                </div>
                            </div>
                        </div>
                        <div className="row mt--60 mt_sm--40">
                            {PostList.map((value , i ) => (
                                <div className="col-lg-4 col-md-6 col-12" key={i}>
                                    <div className="blog blog-style--1">
                                        <div className="thumbnail">
                                            <a href="/blog-details">
                                                <img className="w-100" src={`/assets/images/blog/blog-${value.images}.jpg`} alt="Blog Images"/>
                                            </a>
                                        </div>
                                        <div className="content">
                                            <p className="blogtype">{value.category}</p>
                                            <h4 className="title"><a href="/blog-details">{value.title}</a></h4>
                                            <div className="blog-btn">
                                                <a className="rn-btn text-white" href="/blog-details">Read More</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>    
                    </div>    
                </div>
            </div> */}
      {/* End Blog Area */}

      {/* Start COntact Area */}

      <div
        id="contact"
        className="fix pb-5 pb-md-0 pt-5 pt-md-0 bg_color--1 py-5"
      >
        <div className="rn-contact-area ptb--120 mb-dec--30">
          <ContactThree
            contactImages="/assets/images/about/about-9.jpg"
            contactTitle={texts.contact.title}
          />
        </div>
      </div>
      {/* End COntact Area */}

      <FooterTwo />
      {/* Start Back To Top */}
      <div className="backto-top">
        <ScrollToTop showUnder={160}>
          <FiChevronUp />
        </ScrollToTop>
      </div>
      {/* End Back To Top */}
    </div>
  );
};

export default PortfolioLanding;
